import styled from 'styled-components';
import { theme } from './theme';

const {margin} = theme;

const Footer = styled.footer`
  margin: 0;
  padding: ${margin};
`;

export default Footer;
