import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(25,30)"
          d="M11.8 0.299999H16.8L28.6 31H23.3L20.15 22.5H8.4L5.25 31H7.07805e-08L11.8 0.299999ZM14.25 6.65L10.05 18H18.45L14.25 6.65Z"
          fill="#FF9E64"
        />
      </g>
      <path
        stroke="#FF9E64"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
